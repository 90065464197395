import React from 'react';
import Typography from '@material-ui/core/Typography';
import Footer from '../../../../components/Footer'
import Main from '../../../../components/Main'
import Topbar from '../../../../components/Topbar'
import LottieAnimation from '../../../../components/LottieAnimation'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import Fade from "@material-ui/core/Fade"
import NotFound from '../../../../animations/page_not_found.json';

function CotacaoNaoEncontrada() {

    return (
        <>
            <Topbar />
            <Main>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item >
                        <Fade in timeout={1000}>
                            <Box>
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Box marginBottom={2}>
                                        <LottieAnimation
                                            height={100}
                                            loop
                                            autoplay
                                            animationData={NotFound}
                                        />
                                    </Box>
                                </Grid>
                                <Typography variant="h4" align="center">
                                    Não foi possível encontrar a cotação.
                                </Typography>
                                <Typography variant="subtitle2" align="center">
                                    A cotação pode ter sido excluída ou não estar mais disponível
                                </Typography>
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Main>
            <Footer />
        </>
    );
};

export default CotacaoNaoEncontrada;