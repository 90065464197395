import React from 'react';
import { styleStatus } from './style';
import { styleText } from './style';
import { styleTitle } from './style';

export default function StatusCotacao({ backgroundColor, color, title, text }) {
	return (
		<div style={{ ...styleStatus, backgroundColor, color }}>
			<span style={styleTitle}>{title}</span>
			<span style={styleText}>{text}</span>
		</div>
	);
}
