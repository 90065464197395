import api from '../../../config';

export function buscarCotacaoFornecedor(idCotacao, idFornecedor, onFinish, onError) {
    api.get(`/cotacoes/${idCotacao}/fornecedores/${idFornecedor}`)
        .then(onFinish)
        .catch(onError)
}

export function responderCotacaoFornecedor(idCotacao, idFornecedor, cotacao, onFinish, onError) {
    api.post(`/cotacoes/${idCotacao}/fornecedores/${idFornecedor}`, cotacao)
        .then(onFinish)
        .catch(onError)
}