import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import InputNumber from '../../../../components/InputNumber';
import useStyles from './style';
import produtoSemImagem from './images/no-image.png';
import NumberFormat from 'react-number-format';
import InputDate from '../../../../components/InputDate';
import { validarPrazoEntregaPadrao, buscarValueInicial } from './functions';
import Message from '../../../../components/Message';
import { MenuItem, TextField } from '@material-ui/core';

function CotacaoProdutos(props) {
	const classes = useStyles();
	const [showImage, setShowImage] = useState(false);
	const [produtoSelecionado, setProdutoSelecionado] = useState(null);
	const [ultimoPrazoEntregaAplicado, setUltimoPrazoEntregaAplicado] = useState();
	const [ultimaAliqIcmsAplicada, setUltimaAliqIcmsAplicada] = useState();
	const [ultimaAliqIpiAplicada, setUltimaAliqIpiAplicada] = useState();

	useEffect(() => {
		if (validarPrazoEntregaPadrao(props.prazoEntregaPadrao) && !props.disabled) {
			aplicarValorCampoPadrao('prazoEntrega', props.prazoEntregaPadrao, ultimoPrazoEntregaAplicado);
			setUltimoPrazoEntregaAplicado(props.prazoEntregaPadrao);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.prazoEntregaPadrao]);

	useEffect(() => {
		if (!props.disabled) {
			aplicarValorCampoPadrao('aliqIcms', props.aliqIcmsPadrao, ultimaAliqIcmsAplicada);
			setUltimaAliqIcmsAplicada(props.aliqIcmsPadrao);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.aliqIcmsPadrao]);

	useEffect(() => {
		if (!props.disabled) {
			aplicarValorCampoPadrao('aliqIpi', props.aliqIpiPadrao, ultimaAliqIpiAplicada);
			setUltimaAliqIpiAplicada(props.aliqIpiPadrao);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.aliqIpiPadrao]);

	useEffect(() => {
		if (!props.value || props.value.length === 0) {
			props.onChange(buscarValueInicial(props.produtos));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.produtos]);

	function aplicarValorCampoPadrao(campo, valor, ultimoAplicado) {
		let value = [...props.value];
		value.map(item => {
			// eslint-disable-next-line
			if (item[campo] == ultimoAplicado) {
				item[campo] = valor || null;
			}
			return item;
		});
		props.onChange(value);
	}

	function handleChange(field, identificador, value) {
		let arrayProdutos = [...props.value];

		arrayProdutos &&
			arrayProdutos.map(produto => {
				if (parseInt(produto.identificador) === parseInt(identificador)) {
					produto[field] = value;
				}
				return produto;
			});
		props.onChange(arrayProdutos);
	}

	function getValue(identificador) {
		let result = {};
		if (props.value) {
			for (let i = 0; i < props.value.length; i++) {
				const produto = props.value[i];
				if (produto.identificador === identificador) {
					result = produto;
					break;
				}
			}
		}
		return result;
	}

	function handleShowImage(produto) {
		setProdutoSelecionado(produto);
		setShowImage(true);
	}

	function handleCloseImage(produto) {
		setProdutoSelecionado(produto);
		setShowImage(false);
	}

	function calcularTotal() {
		if (!props.produtos || !props.value) {
			return 0;
		} else {
			let arrayProdutos = [...props.produtos];
			let respProdutos = [...props.value];

			let valorFrete = Number(props.valorFrete) || 0;

			let totalProdutos = 0;

			for (let i = 0; i < arrayProdutos.length; i++) {
				const produto = arrayProdutos[i];
				const resposta = respProdutos[i];

				if (resposta && resposta.valor) {
					totalProdutos += Number(produto.quantidade * (resposta.valor || 0));
				}
			}

			return totalProdutos + valorFrete;
		}
	}

	function valorUnitarioComIPI(valor, aliquotaIpi) {
		let valorDeIpi = valor * (aliquotaIpi / 100);
		return valor + valorDeIpi;
	}
	function textoProdutos() {
		return (
			<span>
				No valor unitário, já deve ser acrescido todos os impostos <b>MENOS</b> o IPI
			</span>
		);
	}

	return (
		<React.Fragment>
			<List
				component="nav"
				subheader={
					<Box className={classes.titulo}>
						<Typography variant="h6">
							<b>Lista de produtos para cotar</b>
						</Typography>
						<Divider variant="fullWidth" />
					</Box>
				}
			>
				<Message severity="info" text={textoProdutos()} />
				{props.produtos &&
					props.produtos.map((produto, key) => {
						const value = getValue(produto.identificador);

						return (
							<React.Fragment key={key}>
								<Box className={classes.boxContainer}>
									<Grid container>
										<Grid item xs={12}>
											<Typography variant="h6" gutterBottom className={classes.nome}>
												<b>{produto.nome}</b>
											</Typography>
										</Grid>
										<Grid item xs container spacing={2} alignItems="center">
											<Grid item xs={3} sm={4} md={4} lg={4} xl={4}>
												<ButtonBase tabIndex="-1" onClick={() => handleShowImage(produto)}>
													<Paper elevation={0} className={classes.card}>
														<img
															alt="Produto imagem"
															className={classes.cardImage}
															title="Imagem do produto"
															src={
																produto
																	? produto.imagem
																		? produto.imagem
																		: produtoSemImagem
																	: null
															}
														/>
													</Paper>
												</ButtonBase>
											</Grid>
											<Grid item container xs={9} sm={8} md={8} lg={8} xl={8}>
												<Grid item xs={12} direction="column" justifyContent="center" container>
													<Typography
														className={classes.detalheProduto}
														variant="body2"
														color="textSecondary"
													>
														Identificador: <b>{produto.identificador}</b>
													</Typography>
													<Typography
														className={classes.detalheProduto}
														variant="body2"
														color="textSecondary"
													>
														Código do produto: <b>{produto.codigoProduto}</b>
													</Typography>
													<Typography
														className={classes.detalheProduto}
														variant="body2"
														color="textSecondary"
													>
														Unidade de medida: <b>{produto.unidadeMedida}</b>
													</Typography>
													<Typography
														className={classes.detalheProduto}
														variant="body2"
														color="textSecondary"
													>
														Quantidade: <b>{produto.quantidade}</b>
													</Typography>
													<Typography
														className={classes.detalheProduto}
														variant="body2"
														color="textSecondary"
													>
														Descritivo: <b>{produto.descritivo}</b>
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={8} alignItems="center" container>
											<Grid item xs={12} md={4}>
												<InputNumber
													className={classes.fieldsLista}
													label="Alíquota de ICMS"
													value={value.aliqIcms}
													onChange={e =>
														handleChange('aliqIcms', produto.identificador, e.target.value)
													}
													suffix=" %"
													size={2}
													disabled={props.disabled}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<InputNumber
													className={classes.fieldsLista}
													label="Alíquota de IPI"
													value={value.aliqIpi}
													onChange={e =>
														handleChange('aliqIpi', produto.identificador, e.target.value)
													}
													suffix=" %"
													size={2}
													disabled={props.disabled}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<InputNumber
													className={classes.fieldsLista}
													label="Valor unitário com ICMS"
													onChange={e =>
														handleChange('valor', produto.identificador, e.target.value)
													}
													value={value.valor}
													disabled={props.disabled}
													size={9}
													prefix="R$ "
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<InputDate
													className={classes.fieldsLista}
													minDateMessage="Prazo de entrega menor que a data atual"
													minDate={new Date()}
													label="Prazo de entrega na empresa"
													value={value.prazoEntrega}
													onChange={e =>
														handleChange('prazoEntrega', produto.identificador, e)
													}
													disablePast
													disabled={props.disabled}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													select
													className={classes.fieldsLista}
													label="Saldo em estoque"
													value={value.saldoEmEstoque ?? ''}
													onChange={e =>
														handleChange(
															'saldoEmEstoque',
															produto.identificador,
															e.target.value
														)
													}
													disabled={props.disabled}
												>
													<MenuItem value={true}>Sim</MenuItem>
													<MenuItem value={false}>Não</MenuItem>
												</TextField>
											</Grid>
											<Grid item xs={12} md={4}>
												<InputNumber
													className={classes.fieldsLista}
													label="Valor unitário com IPI"
													value={valorUnitarioComIPI(value.valor, value.aliqIpi)}
													disabled={true}
													size={9}
													prefix="R$ "
												/>
											</Grid>
											{produto.prazoEntregaEsperado && (
												<Grid item xs={12} md={4}>
													<InputDate
														className={classes.fieldsLista}
														label="Prazo de entrega esperado"
														value={produto.prazoEntregaEsperado}
														disabled
													/>
												</Grid>
											)}
										</Grid>
									</Grid>
								</Box>
								<Divider variant="middle" />
							</React.Fragment>
						);
					})}
				<Box className={classes.marginTotal}>
					<Grid container direction="row" justifyContent="flex-end" alignItems="center">
						<Typography variant="h4" align="center">
							TOTAL:
							<NumberFormat
								value={calcularTotal()}
								displayType="text"
								thousandSeparator="."
								decimalSeparator=","
								prefix=" R$ "
								fixedDecimalScale={true}
								decimalScale={4}
							/>
						</Typography>
					</Grid>
				</Box>
			</List>
			<Dialog onClose={handleCloseImage} open={showImage}>
				<Card className={classes.card}>
					<img
						alt="Imagem do produto"
						className={classes.cardImage}
						title="Imagem do produto"
						src={
							produtoSelecionado
								? produtoSelecionado.imagem
									? produtoSelecionado.imagem
									: produtoSemImagem
								: ''
						}
					/>
				</Card>
			</Dialog>
		</React.Fragment>
	);
}
export default CotacaoProdutos;
