import React from 'react';
import propTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parse, format } from 'date-fns';

function InputDate(props) {

    function handleChange(e) {
        if (!e || String(e) === "Invalid Date") {
            props.onChange(e)
        } else {
            props.onChange(format(e, "dd/MM/yyyy"))
        }
    }

    function getValue() {
        if (props.value) {
            return parse(props.value, "dd/MM/yyyy", new Date())
        }
        return null
    }

    return (
        <KeyboardDatePicker
            {...props}
            disableToolbar
            format="dd/MM/yyyy"
            label={props.label}
            value={getValue()}
            onChange={handleChange}
            animateYearScrolling
            cancelLabel="Cancelar"
            todayLabel="Hoje"
            showTodayButton
            KeyboardButtonProps={{
                tabIndex: -1
            }}
        />
    );
}
InputDate.defaultProps = {
    invalidDateMessage: "Data inválida",
    maxDateMessage: "Data inválida",
    minDateMessage: "Data inválida",

}
InputDate.propTypes = {
    label: propTypes.string,
    onChange: propTypes.func,
    value: propTypes.any,
    invalidDateMessage: propTypes.string,
    maxDateMessage: propTypes.string,
    minDateMessage: propTypes.string,
    minDate: propTypes.any
};

export default InputDate;