import { baseURL } from "../../../config";

const diasParcelasFixas = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600, 630, 660, 690, 720];

export function validarValoresProdutos(produtosRequisitados, produtosRepondidos) {
    if (produtosRequisitados.length !== produtosRepondidos.length) {
        return false
    }

    let validouProdutos = true;
    produtosRepondidos.forEach(produto => {
        if (!produto.valor) {
            validouProdutos = false;
            return;
        }
    })
    return validouProdutos;
}

export function tratarErros(response) {
    if (!response) {
        return "Erro de conexão com a internet!"
    } else if (response && response.data && response.data.details) {
        return response.data.details[0]
    }
}

export function buscarLinkBaxarCotacao(resposta, idFornecedor) {
    if (resposta) {
        return `${baseURL}relatorio_fornecedor_respondido/${idFornecedor}`
    } else {
        return `${baseURL}relatorio_fornecedor/${idFornecedor}`
    }
}

export async function validarFormulario(props) {

    if (Object.getOwnPropertyNames(props.errors).length !== 0) {
        return false;
    }

    const validacao = await props.validateForm(props.values);

    return Object.getOwnPropertyNames(validacao).length === 0;
}

export function gerarArrayParcelas(quantidadeParcelas, diasParcelas) {
    if (!diasParcelas) {
        diasParcelas = []
    }
    let parcelasAntigas = diasParcelas.slice();
    if (quantidadeParcelas && quantidadeParcelas > 0) {
        for (let i = 0; i < quantidadeParcelas; i++) {
            if (!parcelasAntigas[i]) {
                parcelasAntigas[i] = diasParcelasFixas[i];
            }
        }
    }
    return parcelasAntigas;
}

export function modificarValuesParaSalvar(values) {
    let result

    result = Object.assign(values, result)
    let produtos = []

    if (values.indPagto !== "A_PRAZO") {
        result.quantidadeParcelas = null;
        result.diasParcelas = [];
    }

    result.diasParcelas = result.diasParcelas.splice(0, result.quantidadeParcelas)
    result.cnpjTransportadora = result.cnpjTransportadora ? result.cnpjTransportadora.replace(/[^0-9]+/g, '') : undefined;

    result.produtos.forEach(e => {
        if (e.valor) {
            produtos.push(e)
        }
    })
    result.produtos = produtos

    return result
}

export function isValidCnpj(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (String(resultado) !== digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (String(resultado) !== digitos.charAt(1))
        return false;

    return true;

}