import makeStyles from '@material-ui/styles/makeStyles';

const styles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.white,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    iconButtons: {
        margin: theme.spacing(0),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    }
}));

export default styles;