import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

function DialogConfirmarResposta({ open, handleClose, texto }) {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Confirmação</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {texto}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Voltar
                </Button>
                <Button onClick={() => handleClose(true)} variant="contained" color="primary" autoFocus>
                    Continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogConfirmarResposta.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    texto: PropTypes.string
}

export default DialogConfirmarResposta;