import React from 'react';

import LottieAnimation from '../../components/LottieAnimation';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import Fade from "@material-ui/core/Fade"
import Footer from '../../components/Footer'
import Main from '../../components/Main'
import Topbar from '../../components/Topbar'
import PageNotFound from '../../animations/page_not_found.json';


function NotFound() {
	return (
		<>
			<Topbar />

			<Main>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<Fade in timeout={1000}>
						<Box>
							<Box marginBottom={2}>
								<LottieAnimation
									height={150}
									loop
									autoplay
									animationData={PageNotFound}
								/>
							</Box>

							<Typography variant="h4" align="center">
								A página que você procura não está aqui
							</Typography>
							<Typography variant="subtitle2" align="center">
								Você tentou acessar uma rota que não existe ou veio aqui por engano.
							</Typography>
						</Box>
					</Fade>
				</Grid>
			</Main>

			<Footer />
		</>
	);
};

export default NotFound;
