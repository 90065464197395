import React from 'react';
import LottieAnimation from '../../../../components/LottieAnimation';
import Grid from '@material-ui/core/Grid';
import Success from '../../../../animations/success.json';
import PropTypes from 'prop-types';
import StatusCotacao from '../StatusCotacao';

function CotacaoRespondida({ hidden }) {
	if (hidden) {
		return null;
	}
	return (
		<Grid container justifyContent="center" alignItems="center" direction="column" hidden={hidden}>
			<Grid item>
				<Grid container justifyContent="center" alignItems="center">
					<LottieAnimation height={75} autoplay animationData={Success} />
				</Grid>
				<StatusCotacao
					title={'Cotação respondida com sucesso!'}
					text={'A cotação foi respondida e encaminhada à empresa solicitante'}
					backgroundColor={'#dcedc8'}
					color={'green'}
				/>
			</Grid>
		</Grid>
	);
}

CotacaoRespondida.propTypes = {
	hidden: PropTypes.bool,
};

export default CotacaoRespondida;
