import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import WebIcon from '@material-ui/icons/Web';
import Box from '@material-ui/core/Box';
import getYear from 'date-fns/getYear';
import useStyles from './style';

function Footer() {
	const classes = useStyles();

	return (
		<Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.root}>
			<Box component="span">
				<Tooltip title="Facebook">
					<Link component="a" href="https://www.facebook.com/MicrosysOficial/" target="_blank" tabIndex="-1">
						<IconButton className={classes.iconButtons} aria-label="delete" tabIndex="-1">
							<FacebookIcon />
						</IconButton>
					</Link>
				</Tooltip>

				<Tooltip title="LinkedIn">
					<Link
						component="a"
						href="https://www.linkedin.com/company/microsys-sistemas-ltda"
						target="_blank"
						tabIndex="-1"
					>
						<IconButton className={classes.iconButtons} aria-label="delete" tabIndex="-1">
							<LinkedInIcon />
						</IconButton>
					</Link>
				</Tooltip>

				<Tooltip title="Instagram">
					<Link component="a" href="https://www.instagram.com/microsysoficial/" target="_blank" tabIndex="-1">
						<IconButton className={classes.iconButtons} aria-label="delete" tabIndex="-1">
							<InstagramIcon />
						</IconButton>
					</Link>
				</Tooltip>

				<Tooltip title="Website">
					<Link component="a" href="http://www.microsys.inf.br/s/" target="_blank" tabIndex="-1">
						<IconButton className={classes.iconButtons} aria-label="delete" tabIndex="-1">
							<WebIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</Box>
			<Typography variant="caption" align="center">
				A Microsys Sistemas tem mais de 25 anos de história e é uma das melhores empresas do ramo no sul do
				país.{' '}
				<Link component="a" href="http://microsys.inf.br" target="_blank" tabIndex="-1">
					Microsys®
				</Link>
				.{getYear(new Date())}
			</Typography>
		</Grid>
	);
}

export default Footer;
