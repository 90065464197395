import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import useStyles from './style';

function CardFornecedor(props) {
	const classes = useStyles();
	const { informacoesCotacao } = props;
	return (
		<Card className={classes.card}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography variant="h6" display="block" className={classes.titleInformacoesCards}>
					<b>Fornecedor:</b>
				</Typography>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
					Nome do fornecedor: <b>{informacoesCotacao.fornecedor.nome}</b>
				</Typography>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
					Data de expiração: <b>{informacoesCotacao.dataExpiracao}</b>
				</Typography>
			</Grid>
		</Card>
	);
}

export default CardFornecedor;
