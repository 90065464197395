import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';

//Styles
import useStyles from './style';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const anchorOriginVariations = {
    topLeft: { vertical: 'top', horizontal: 'left' },
    bottomLeft: { vertical: 'bottom', horizontal: 'left' },

    topCenter: { vertical: 'top', horizontal: 'center' },
    bottomCenter: { vertical: 'bottom', horizontal: 'center' },

    topRight: { vertical: 'top', horizontal: 'right' },
    bottomRight: { vertical: 'bottom', horizontal: 'right' },
};

function actionButton(onClose, icon) {
    return (
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={icon} />
        </IconButton>
    );
}

function Snackbars(props) {
    const classes = useStyles();

    const { className, message, onClose, variant, open, autoHideDuration, orientation, closable, ...other } = props;

    const Icon = variantIcon[variant];

    return (
        <Snackbar
            className={clsx(classes[variant], className)}
            anchorOrigin={anchorOriginVariations[orientation]}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
        >
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={closable ? actionButton(onClose, classes.icon) : null}
                {...other}
            />
        </Snackbar>

    );
}

Snackbars.defaultProps = {
    autoHideDuration: 6000,
    orientation: 'bottomLeft',
    variant: 'success',
    closable: true
}

Snackbars.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    autoHideDuration: PropTypes.number,
    open: PropTypes.bool.isRequired,
    closable: PropTypes.bool.isRequired,
    orientation: PropTypes.oneOf(['topLeft', 'bottomLeft', 'topRight', 'bottomRight', 'topCenter', 'bottomCenter'])
};

export default Snackbars;