import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import useStyles from './style';

function CardSolicitante(props) {
	const classes = useStyles();
	const { informacoesCotacao } = props;
	return (
		<Card className={classes.card}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography variant="h6" display="block" className={classes.titleInformacoesCards}>
					<b>Solicitante:</b>
				</Typography>
			</Grid>
			<Grid container display="flex" direction="row">
				<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
					<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
						Número: <b>{informacoesCotacao?.codigo}</b>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
					<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
						Nome do solicitante: <b>{informacoesCotacao?.nomeEmpresa}</b>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
					<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
						Usuário: <b>{informacoesCotacao?.usuarioSistemaInterno}</b>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
					<Typography variant="subtitle2" display="block" className={classes.titleInformacoesCards}>
						CNPJ do solicitante: <b>{informacoesCotacao?.cnpjEmpresa}</b>
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
}

export default CardSolicitante;
