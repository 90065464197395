import React from 'react';
import useStyles from './style';

function Main(props) {

    const classes = useStyles();

    return (
        <main className={classes.content} style={props.style}>
            {props.children}
        </main>
    )
};

export default Main;

