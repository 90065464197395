import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	titleInformacoes: {
		wordBreak: 'break-word',
	},
	card: {
		paddingTop: theme.spacing(2),
		paddingRigth: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(3),
	},
}));

export default useStyles;
