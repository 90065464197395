import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dialog: {
    },
    message: {
        color: "#fff"
    },
    background: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    }
}));
export default useStyles;