import React from 'react';
import LottieAnimation from '../../../../components/LottieAnimation';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Wait from '../../../../animations/wait.json';
import PropTypes from 'prop-types';
import StatusCotacao from '../StatusCotacao';

function CotacaoAguardandoResposta({ hidden }) {
	if (hidden) {
		return null;
	}
	return (
		<Grid container justifyContent="center" alignItems="center" direction="column" hidden={hidden}>
			<Grid item>
				<Grid container justifyContent="center" alignItems="center">
					<Box>
						<LottieAnimation height={75} autoplay animationData={Wait} />
					</Box>
				</Grid>
				<StatusCotacao
					title={'Aguardando sua resposta'}
					text={'Responda a cotação à empresa solicitante'}
					backgroundColor={'#b3e5fc'}
					color={'#01579b'}
				/>
			</Grid>
		</Grid>
	);
}

CotacaoAguardandoResposta.propTypes = {
	hidden: PropTypes.bool,
};

export default CotacaoAguardandoResposta;
