import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import LogoMicrosys from './images/marca_microsys.png';
import useStyles from './style';

function Topbar(props) {

    const classes = useStyles();

    return (
        <AppBar
            color="primary"
            position="fixed"
        >
            <Toolbar>
                <Box className={classes.title}>
                    <img
                        alt="Logo Microsys Sistemas"
                        src={LogoMicrosys}
                        tabIndex="-1"
                        height="51px"
                    />
                </Box>
                <span>
                    {props.actions}
                </span>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    actions: PropTypes.node
}

export default Topbar;
