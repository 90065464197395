export const styleStatus = {
	borderRadius: '38px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '16px 32px',
};

export const styleTitle = {
	fontWeight: 'bold',
	fontSize: '14px',
};

export const styleText = {
	textAlign: 'center',
	fontWeight: '500',
	fontSize: '12px',
	padding: '2px 0px',
	opacity: 0.8,
};
