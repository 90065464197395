import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	formContent: {
		padding: theme.spacing(1),
	},
	buttonResponder: {
		marginTop: theme.spacing(3.5),
		marginBottom: theme.spacing(2),
	},
	resumo: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(3),
	},
	secao: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(3),
	},
	titleInformacoes: {
		wordBreak: 'break-word',
	},
	checkbox: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
}));
export default useStyles;
