import React from 'react';
import Typography from '@material-ui/core/Typography';
import Footer from '../../../../components/Footer'
import Main from '../../../../components/Main'
import Topbar from '../../../../components/Topbar'
import LottieAnimation from '../../../../components/LottieAnimation'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box'
import Fade from "@material-ui/core/Fade"
import NotFound from '../../../../animations/no_connection.json';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

function SemConexao({ onRefreshClick }) {

    return (
        <>
            <Topbar />
            <Main>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item >
                        <Fade in timeout={1000}>
                            <Box>
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Box marginBottom={2}>
                                        <LottieAnimation
                                            height={100}
                                            loop
                                            autoplay
                                            animationData={NotFound}
                                        />
                                    </Box>
                                </Grid>
                                <Typography variant="h4" align="center">
                                    Não foi possível conectar-se à internet.
                                </Typography>
                                <Typography variant="subtitle2" align="center">
                                    Verifique a conexão e tente novamente mais tarde.
                                </Typography>
                                <Box margin={2}>
                                    <Grid container justifyContent="center" alignItems="center" >
                                        <Tooltip title="Atualizar a página">
                                            <Fab color="primary" size="small" aria-label="add" onClick={onRefreshClick}>
                                                <RefreshIcon />
                                            </Fab>
                                        </Tooltip>
                                    </Grid>
                                </Box>
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Main>
            <Footer />
        </>
    );
};

SemConexao.propTypes = {
    onRefreshClick: PropTypes.func
}

export default SemConexao;