import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';

function LottieAnimation(props) {
    const { loop, autoplay, animationData, rendererSettings, ...rest } = props;

    return (
        <Lottie
            options={{ loop, autoplay, animationData, rendererSettings }}
            {...rest}
        />
    );
};

LottieAnimation.defaultProps = {
    loop: false
}

LottieAnimation.propTypes = {
    loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    autoplay: PropTypes.bool.isRequired,
    animationData: PropTypes.any.isRequired,
    rendererSettings: PropTypes.object
};

export default LottieAnimation;