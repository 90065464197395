import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
        marginTop: 5
    },
}));

export default useStyles;
