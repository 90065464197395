import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from './theme';
import CotacaoView from './views/Cotacao';
import NotFoundView from './views/NotFound';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';

function App() {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
					<Router>
						<Routes>
							<Route
								exact
								path="/cotacao/:idCotacao/fornecedor/:idFornecedor"
								element={<CotacaoView />}
							/>
							<Route exact path="/not-found" element={<NotFoundView />} />
							<Route path="*" element={<NotFoundView />} />
						</Routes>
					</Router>
				</MuiPickersUtilsProvider>
			</ThemeProvider>
		</>
	);
}

export default App;
