import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask'
import TextField from '@material-ui/core/TextField';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
        />
    );
};

function InputTextMask(props) {
    return (
        <TextField
            className={props.className}
            label={props.label}
            value={props.value}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            id={props.id}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            InputProps={{
                inputComponent: TextMaskCustom,
                ...props.InputProps
            }}
            inputProps={{
                mask: props.mask,
                guide: props.guide,
                placeholderChar: props.placeholderChar,
                keepCharPositions: props.keepCharPositions,
                pipe: props.pipe,
                showMask: props.showMask
            }}
        />
    );
};

InputTextMask.propTypes = {
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    guide: PropTypes.bool,
    placeholderChar: PropTypes.string,
    keepCharPositions: PropTypes.bool,
    pipe: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
    showMask: PropTypes.bool,
};

InputTextMask.defaultProps = {
    guide: false,
    keepCharPositions: false,
    showMask: false
};

export default InputTextMask;
