import React from 'react';
import propTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    const handleChange = event => {
        let floatValue = event.floatValue
        let tamanhoNumero = String(event.floatValue).split(".")[0].length;

        if (event.value && tamanhoNumero > props.size) {
            floatValue = props.value
        }

        onChange({
            target: {
                value: floatValue
            }
        })
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={handleChange}
            prefix={props.prefix}
            value={props.value}
            suffix={props.suffix}
            decimalScale={props.decimalScale}
            fixedDecimalScale={true}
            allowNegative={false}
            thousandSeparator='.'
            decimalSeparator=','
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: propTypes.func.isRequired,
    onChange: propTypes.func.isRequired,
    size: propTypes.number,
};

function InputNumber(props) {

    return (
        <TextField
            className={props.className}
            label={props.label}
            value={props.value}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            id={props.id}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            InputProps={{
                inputComponent: NumberFormatCustom,
                ...props.InputProps
            }}
            inputProps={{
                prefix: props.prefix,
                suffix: props.suffix,
                decimalScale: props.decimalScale,
                size: props.size,
                isAllowed: props.isAllowed
            }}
        />
    );
}

InputNumber.propTypes = {
    prefix: propTypes.string,
    suffix: propTypes.string,
    id: propTypes.string,
    className: propTypes.string,
    onChange: propTypes.func,
    name: propTypes.string,
    value: propTypes.number,
    label: propTypes.string,
    decimalScale: propTypes.number,
    onBlur: propTypes.func,
    size: propTypes.number
};

InputNumber.defaultProps = {
    decimalScale: 4 
};

export default InputNumber;
