import { initialValues } from "..";
import { gerarArrayParcelas } from ".";

export function converterCotacaoParaFormulario(cotacao) {
    if(!cotacao.quantidadeParcelas){
        cotacao.quantidadeParcelas = 1
    }
    const resposta = cotacao.fornecedor.resposta || {}
    const valoresFixos = cotacao.fornecedor.valoresFixos || {}

    return {
        ...initialValues,
        ...valoresFixos,
        ...resposta,
        diasParcelas: gerarArrayParcelas(resposta.quantidadeParcelas || valoresFixos.quantidadeParcelas || 1, resposta.diasParcelas || valoresFixos.diasParcelas)
    }
}