import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	boxContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginTotal: {
		paddingTop: theme.spacing(3),
		marginRight: theme.spacing(2),
	},
	card: {
		display: 'flex',
	},
	cardImage: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
	nome: {
		wordBreak: 'break-word',
	},
	titulo: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	detalheProduto: {
		wordBreak: 'break-word',
	},
	fieldsLista: {
		margin: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));
export default useStyles;
