import { createTheme } from '@material-ui/core';

import { palette } from './palette';
import { typography } from './typography';

const theme = createTheme({
	palette,
	typography,
	props: {
		MuiTextField: {
			variant: 'outlined',
			fullWidth: true,
			margin: 'dense',
		},
		MuiInputLabel: {
			shrink: true,
			variant: 'outlined',
		},
		MuiOutlinedInput: {
			notched: true,
		},
		MuiSelect: {
			variant: 'outlined',
			fullWidth: true,
		},
		MuiFormControl: {
			fullWidth: true,
		},
	},
});

export default theme;
