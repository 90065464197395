import React from 'react';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputNumber from '../../../../components/InputNumber';

function Parcelas(props) {

    function handleChangeParcelas(event, key) {
        let arrayResultante = props.diasParcelas.slice();

        if (!event.target.value || Number(event.target.value) < 0) {
            arrayResultante[key] = "";
        } else {
            arrayResultante[key] = Number(event.target.value);
        }
        props.onChange(arrayResultante);
    }

    return (
        <>
            {props.diasParcelas && props.diasParcelas.map((parcela, key) => {
                if (key < props.quantidadeParcelas) {
                    return (
                        <Grid key={key} item xs={3} sm={2} md={2} lg={2} xl={2}>
                            <InputNumber
                                key={key}
                                id={`parcela_${key}`}
                                disabled={props.disabled}
                                label={`Parcela ${key + 1}`}
                                name={`parcela_${key}`}
                                value={parcela}
                                onChange={event => handleChangeParcelas(event, key)}
                                size={3}
                                decimalScale={0}
                            />
                        </Grid>
                    )
                }
                return null;
            })}
        </>
    );
};

Parcelas.propTypes = {
    diasParcelas: PropTypes.array,
    onChange: PropTypes.func,
    values: PropTypes.object
}

export default Parcelas;
