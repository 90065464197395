import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import LottieAnimation from '../../../../components/LottieAnimation';
import Loading from '../../../../animations/loading.json';
import useStyles from './style';

function CarregandoCotacao({ open, handleClose }) {

    const classes = useStyles();

    return (
        <Dialog
            className={classes.dialog}
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.background
            }}
        >
            <Fade in timeout={1000}>
                <Box>
                    <Grid container justifyContent="center" alignItems="center" >
                        <LottieAnimation
                            height={100}
                            loop
                            autoplay
                            animationData={Loading}
                        />
                    </Grid>
                    <Typography className={classes.message} variant="h4" align="center">
                        Carregando, por favor aguarde...
                    </Typography>
                </Box>
            </Fade>
        </Dialog>
    );
};

CarregandoCotacao.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
}

export default CarregandoCotacao;