import React, { useState, useEffect, useMemo } from 'react';
import { withFormik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Send from '@material-ui/icons/Send';
import Footer from '../../components/Footer';
import Main from '../../components/Main';
import Topbar from '../../components/Topbar';
import InputNumber from '../../components/InputNumber';
import Snackbar from '../../components/Snackbar';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
	validarValoresProdutos,
	tratarErros,
	buscarLinkBaxarCotacao,
	gerarArrayParcelas,
	modificarValuesParaSalvar,
	isValidCnpj,
} from './functions';
import { buscarCotacaoFornecedor, responderCotacaoFornecedor } from './requests';
import useStyles from './style';
import CarregandoCotacao from './components/CarregandoCotacao';
import CotacaoAguardandoResposta from './components/CotacaoAguardandoResposta';
import CotacaoExpirada from './components/CotacaoExpirada';
import CotacaoNaoEncontrada from './components/CotacaoNaoEncontrada';
import CotacaoProdutos from './components/CotacaoProdutos';
import SemConexao from './components/SemConexao';
import DialogConfirmarResposta from './components/DialogConfirmarResposta';
import CotacaoRespondida from './components/CotacaoRespondida';
import InputDate from '../../components/InputDate';
import Parcelas from './components/Parcelas';
import InputTextMask from '../../components/InputTextMask';
import { converterCotacaoParaFormulario } from './functions/cotacaoConverter';
import { isValid, parse, isAfter, isBefore, addDays } from 'date-fns';
import CardSolicitante from './components/CardSolicitante';
import CardFornecedor from './components/CardFornecedor';

export const initialValues = {
	indPagto: 'A_VISTA',
	indFrete: 'SEM_FRETE',
	razaoSocialTransportadora: '',
	cnpjTransportadora: '',
	quantidadeParcelas: 1,
	diasParcelas: [30],
	observacao: '',
	produtos: [],
	prazoEntregaPadrao: null,
	aliqIpiPadrao: null,
	aliqIcmsPadrao: null,
	diasFixos: null,
};

function Cotacao(props) {
	const classes = useStyles();
	const { values, handleChange, handleBlur, errors, setFieldValue, resetForm } = props;

	const [carregandoCotacao, setCarregandoCotacao] = useState(true);
	const [snackBarErrorVisible, setSnackBarErrorVisible] = useState(false);
	const [snackBarCotacaoRespondidaVisible, setSnackBarCotacaoRespondidaVisible] = useState(false);
	const [dialogConfirmarVisible, setDialogConfirmarVisible] = useState(false);
	const [erros, setErros] = useState('');
	const [cotacaoRespondida, setCotacaoRespondida] = useState(false);
	const { idCotacao, idFornecedor } = useParams();
	const [informacoesCotacao, setInformacoesCotacao] = useState({ fornecedor: {}, produtos: [] });
	const [valoresFixos, setValoresFixos] = useState({});

	function buscarCotacao() {
		setCarregandoCotacao(true);
		buscarCotacaoFornecedor(
			idCotacao,
			idFornecedor,
			({ data }) => {
				setCotacaoRespondida(data.fornecedor.resposta !== null);
				resetForm({ values: converterCotacaoParaFormulario(data) });
				delete data.fornecedor.resposta;
				setValoresFixos(data.fornecedor.valoresFixos || {});
				setInformacoesCotacao(data);
				setCarregandoCotacao(false);
			},
			({ response }) => {
				setErros(tratarErros(response));
				setSnackBarErrorVisible(true);
				setCarregandoCotacao(false);
			}
		);
	}

	React.useEffect(() => {
		buscarCotacao();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		props.setFieldValue('diasParcelas', gerarArrayParcelas(values.quantidadeParcelas, values.diasParcelas));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.quantidadeParcelas]);

	async function responderCotacao() {
		setCarregandoCotacao(true);

		const dadosFormulario = modificarValuesParaSalvar(values);
		responderCotacaoFornecedor(
			idCotacao,
			idFornecedor,
			dadosFormulario,
			() => {
				buscarCotacao();
				setCarregandoCotacao(false);
				setSnackBarCotacaoRespondidaVisible(true);
			},
			({ response }) => {
				setErros(tratarErros(response));

				setCarregandoCotacao(false);
			}
		);
	}

	useEffect(() => {
		if (erros) {
			setSnackBarErrorVisible(true);
		}
	}, [erros]);

	async function onResponderCotacaoClick() {
		const validacao = await props.validateForm(props.values);

		if (Object.getOwnPropertyNames(validacao).length > 0) {
			setErros(validacao[Object.keys(validacao)[0]]);
			return;
		} else {
			setDialogConfirmarVisible(true);
		}
	}

	function onCloseDialogConfirmar(confirmou) {
		if (confirmou === true) {
			responderCotacao();
		}
		setDialogConfirmarVisible(false);
	}

	function onRefresh() {
		buscarCotacao();
		setErros('');
		setSnackBarErrorVisible(false);
	}

	function handleChangeAliquota(field, value) {
		if (value === 0) {
			setFieldValue(field, null);
		} else {
			setFieldValue(field, value);
		}
	}

	function handleChangeIndFrete(value) {
		if (value.target.value !== 'DESTINATARIO') {
			setFieldValue('valorFrete', null);
			setFieldValue('icmsSobreFrete', null);
		}
		handleChange(value);
	}

	function handleChangeIndPagto(value) {
		if (value.target.value !== 'A_PRAZO') {
			handleChange(value);
			setFieldValue('quantidadeParcelas', 1);
			setFieldValue('diasParcelas', [30]);
		} else {
			handleChange(value);
		}
	}

	function handleChangeQtdParcela(field, value) {
		if (!value || value < 0) {
			setFieldValue(field, null);
		} else if (value > 24) {
			setFieldValue(field, 24);
		} else {
			setFieldValue(field, value);
		}
	}

	function buscarTextoDialogConfirmar() {
		if (validarValoresProdutos(informacoesCotacao.produtos, values.produtos)) {
			return 'Após responder, a cotação não poderá mais ser modificada, deseja continuar?';
		} else {
			return 'Não foram informados valores para um ou mais produtos da cotação, portanto estes produtos não serão cotados, deseja continuar?';
		}
	}

	const desabilitarCampos = informacoesCotacao.situacao !== 'AGUARDANDO_RESPOSTAS' || cotacaoRespondida;

	const produtos = useMemo(
		() => (
			<CotacaoProdutos
				produtos={informacoesCotacao.produtos}
				disabled={desabilitarCampos}
				value={values.produtos}
				valorFrete={values.valorFrete}
				onChange={value => setFieldValue('produtos', value)}
				prazoEntregaPadrao={values.prazoEntregaPadrao}
				aliqIcmsPadrao={values.aliqIcmsPadrao}
				aliqIpiPadrao={values.aliqIpiPadrao}
			/>
		),
		[
			informacoesCotacao,
			desabilitarCampos,
			values.produtos,
			values.prazoEntregaPadrao,
			values.aliqIcmsPadrao,
			values.aliqIpiPadrao,
			values.valorFrete,
			setFieldValue,
		]
	);

	const parcelas = useMemo(
		() => (
			<Parcelas
				disabled={
					desabilitarCampos || (Boolean(valoresFixos.diasParcelas) && valoresFixos.diasParcelas.length > 0)
				}
				quantidadeParcelas={values.quantidadeParcelas}
				diasParcelas={values.diasParcelas}
				onChange={arrayParcelas => setFieldValue('diasParcelas', arrayParcelas)}
			/>
		),
		[valoresFixos, values.quantidadeParcelas, values.diasParcelas, setFieldValue, desabilitarCampos]
	);

	if (erros === 'Cotação não encontrada.' && !informacoesCotacao.codigo) {
		return <CotacaoNaoEncontrada />;
	} else if (erros === 'Erro de conexão com a internet!' && !informacoesCotacao.codigo) {
		return <SemConexao onRefreshClick={onRefresh} />;
	} else {
		return (
			<>
				<CarregandoCotacao open={carregandoCotacao} />
				<Snackbar
					variant="error"
					message={erros}
					open={snackBarErrorVisible}
					onClose={() => {
						setSnackBarErrorVisible(false);
						setErros('');
					}}
				/>
				<Snackbar
					variant="success"
					message="Cotação respondida com sucesso!"
					open={snackBarCotacaoRespondidaVisible}
					onClose={() => setSnackBarCotacaoRespondidaVisible(false)}
				/>
				<Topbar
					actions={
						<Link
							href={buscarLinkBaxarCotacao(cotacaoRespondida, idFornecedor)}
							target="_blank"
							tabIndex="-1"
						>
							<Tooltip title="Imprimir cotação de preços">
								<IconButton
									edge="start"
									color="secondary"
									className={classes.menuButton}
									size="medium"
									aria-label="menu"
									tabIndex="-1"
								>
									<PrintIcon />
								</IconButton>
							</Tooltip>
						</Link>
					}
				/>

				<Main>
					<Grid container direction="row" justifyContent="center">
						<Container maxWidth="md" className={classes.container}>
							<Grid item container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h3" component="h1" align="center">
										Cotação eletrônica de preços
									</Typography>
								</Grid>
								<Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container justifyContent="center" alignItems="center">
										<CotacaoExpirada
											dataExpiracao={informacoesCotacao.dataExpiracao}
											hidden={!(informacoesCotacao.situacao === 'EXPIRADA' && !cotacaoRespondida)}
										/>
										<CotacaoRespondida
											hidden={!cotacaoRespondida}
											dataExpiracao={informacoesCotacao.dataExpiracao}
										/>
										<CotacaoAguardandoResposta
											hidden={
												!(
													!cotacaoRespondida &&
													informacoesCotacao.situacao !== 'EXPIRADA' &&
													informacoesCotacao.codigo
												)
											}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.secao}>
									<Grid container direction="row">
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.formContent}>
											<CardSolicitante informacoesCotacao={informacoesCotacao} />
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.formContent}>
											<CardFornecedor informacoesCotacao={informacoesCotacao} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box className={classes.secao}>
									<Typography variant="h6">
										<b>Informações sobre o pagamento</b>
									</Typography>
									<Divider variant="fullWidth" />
								</Box>

								<Grid container spacing={1} className={classes.formContent}>
									<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
										<TextField
											select
											label="Indicação de Pagamento"
											name="indPagto"
											error={Boolean(errors.indPagto)}
											value={values.indPagto}
											onChange={handleChangeIndPagto}
											onBlur={handleBlur}
											helperText={errors.indPagto}
											disabled={desabilitarCampos || Boolean(valoresFixos.indPagto)}
										>
											<MenuItem value="A_VISTA">À vista</MenuItem>
											<MenuItem value="A_PRAZO">À prazo</MenuItem>
											<MenuItem value="SEM_PAGAMENTO">Sem pagamento</MenuItem>
										</TextField>
									</Grid>
									{values.indPagto === 'A_PRAZO' && (
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputNumber
												label="Quantidade de parcelas"
												name="quantidadeParcelas"
												helperText={errors.quantidadeParcelas}
												error={Boolean(errors.quantidadeParcelas)}
												onChange={e =>
													handleChangeQtdParcela('quantidadeParcelas', e.target.value)
												}
												value={values.quantidadeParcelas}
												onBlur={handleBlur}
												decimalScale={0}
												disabled={desabilitarCampos || Boolean(valoresFixos.quantidadeParcelas)}
											/>
										</Grid>
									)}
								</Grid>
								{values.indPagto === 'A_PRAZO' && (
									<Grid container spacing={1} className={classes.formContent}>
										{parcelas}
									</Grid>
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box className={classes.secao}>
									<Typography variant="h6">
										<b>Informações sobre a entrega</b>
									</Typography>
									<Divider variant="fullWidth" />
								</Box>

								<Grid container spacing={1} className={classes.formContent}>
									<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
										<TextField
											label="Razão social da transportadora"
											name="razaoSocialTransportadora"
											value={values.razaoSocialTransportadora}
											onChange={e =>
												e.target.value.length < 100 &&
												setFieldValue('razaoSocialTransportadora', e.target.value)
											}
											onBlur={handleBlur}
											multiline
											maxRows={3}
											onKeyPress={e => {
												e.key === 'Enter' && e.preventDefault();
											}}
											error={Boolean(errors.razaoSocialTransportadora)}
											helperText={errors.razaoSocialTransportadora}
											disabled={
												desabilitarCampos || Boolean(valoresFixos.razaoSocialTransportadora)
											}
										/>
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
										<InputTextMask
											label="CNPJ da transportadora"
											name="cnpjTransportadora"
											error={Boolean(errors.cnpjTransportadora)}
											helperText={errors.cnpjTransportadora}
											onChange={e => setFieldValue('cnpjTransportadora', e.target.value)}
											onBlur={handleBlur}
											value={values.cnpjTransportadora}
											mask={[
												/\d/,
												/\d/,
												'.',
												/\d/,
												/\d/,
												/\d/,
												'.',
												/\d/,
												/\d/,
												/\d/,
												'/',
												/\d/,
												/\d/,
												/\d/,
												/\d/,
												'-',
												/\d/,
												/\d/,
											]}
											disabled={desabilitarCampos || Boolean(valoresFixos.cnpjTransportadora)}
										/>
									</Grid>

									<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
										<TextField
											select
											label="Indicação de frete"
											name="indFrete"
											error={Boolean(errors.indFrete)}
											helperText={errors.indFrete}
											value={values.indFrete}
											onChange={value => handleChangeIndFrete(value)}
											onBlur={handleBlur}
											disabled={desabilitarCampos || Boolean(valoresFixos.indFrete)}
										>
											<MenuItem value="EMITENTE">Emitente</MenuItem>
											<MenuItem value="DESTINATARIO">Destinatário</MenuItem>
											<MenuItem value="TERCEIRO">Terceiro</MenuItem>
											<MenuItem value="SEM_FRETE">Sem frete</MenuItem>
										</TextField>
									</Grid>

									{values.indFrete === 'DESTINATARIO' && (
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputNumber
												label="Valor do frete"
												name="valorFrete"
												error={Boolean(errors.valorFrete)}
												helperText={errors.valorFrete}
												onChange={e => setFieldValue('valorFrete', e.target.value)}
												value={values.valorFrete}
												onBlur={handleBlur}
												disabled={desabilitarCampos || Boolean(valoresFixos.valorFrete)}
												size={9}
												decimalScale={2}
												prefix="R$ "
											/>
										</Grid>
									)}
									{values.indFrete === 'DESTINATARIO' && (
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputNumber
												label="Alíquota de ICMS sobre o frete"
												name="icmsSobreFrete"
												helperText={errors.icmsSobreFrete}
												error={Boolean(errors.icmsSobreFrete)}
												onChange={e => setFieldValue('icmsSobreFrete', e.target.value)}
												value={values.icmsSobreFrete}
												onBlur={handleBlur}
												suffix=" %"
												size={2}
												decimalScale={0}
												disabled={desabilitarCampos || Boolean(valoresFixos.icmsSobreFrete)}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box className={classes.secao}>
									<Typography variant="h6">
										<b>Observações</b>
									</Typography>
									<Divider variant="fullWidth" />
								</Box>
								<Grid container spacing={1} className={classes.formContent}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<TextField
											id="observacao"
											name="observacao"
											value={values.observacao || ''}
											onChange={handleChange}
											onBlur={handleBlur}
											maxRows={18}
											error={Boolean(errors.observacao)}
											helperText={
												!errors.observacao
													? `${values.observacao.length}/2000`
													: `${errors.observacao}, informados(${values.observacao.length})`
											}
											label="Observação"
											multiline
											disabled={desabilitarCampos || Boolean(valoresFixos.observacao)}
										/>
									</Grid>
								</Grid>

								<Box
									hidden={
										cotacaoRespondida || (values && values.produtos && values.produtos.length === 1)
									}
								>
									<Box className={classes.secao}>
										<Typography variant="h6">
											<b>Preenchimento automático</b>
										</Typography>
										<Divider variant="fullWidth" />
									</Box>
									<Grid container spacing={1} className={classes.formContent}>
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputNumber
												label="Alíquota de ICMS"
												value={values.aliqIcmsPadrao}
												onChange={e => handleChangeAliquota('aliqIcmsPadrao', e.target.value)}
												suffix=" %"
												size={2}
												disabled={desabilitarCampos}
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputNumber
												label="Alíquota de IPI"
												value={values.aliqIpiPadrao}
												onChange={e => handleChangeAliquota('aliqIpiPadrao', e.target.value)}
												suffix=" %"
												size={2}
												disabled={desabilitarCampos}
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<InputDate
												label="Prazo para entrega na empresa"
												minDateMessage="Prazo de entrega menor que a data atual"
												minDate={new Date()}
												value={values.prazoEntregaPadrao}
												disablePast
												onChange={e => setFieldValue('prazoEntregaPadrao', e)}
												disabled={desabilitarCampos}
											/>
										</Grid>
									</Grid>
								</Box>
								{produtos}
							</Grid>
							<Grid container justifyContent="center" alignItems="center">
								<Box hidden={desabilitarCampos}>
									<Button
										className={classes.buttonResponder}
										variant="contained"
										color="primary"
										size="large"
										onClick={onResponderCotacaoClick}
										startIcon={<Send />}
									>
										Responder cotação
									</Button>
								</Box>
							</Grid>
						</Container>
					</Grid>
				</Main>
				<DialogConfirmarResposta
					open={dialogConfirmarVisible}
					texto={buscarTextoDialogConfirmar()}
					handleClose={onCloseDialogConfirmar}
				/>
				<Footer />
			</>
		);
	}
}

const CotacaoForm = withFormik({
	validateOnChange: true,
	validateOnBlur: true,

	mapPropsToValues: () => {
		return initialValues;
	},
	validate: values => {
		const { cnpjTransportadora, quantidadeParcelas, diasParcelas, indPagto, produtos } = values;
		const errors = {};

		if (cnpjTransportadora && !isValidCnpj(cnpjTransportadora)) {
			errors.cnpjTransportadora = 'O CNPJ informado é inválido';
		}
		if (indPagto === 'A_PRAZO') {
			if (!quantidadeParcelas) {
				errors.quantidadeParcelas = 'Quantidade de parcelas inválida';
			}
		}

		for (const produto of produtos) {
			if (produto.prazoEntrega) {
				const prazoEntregaDate = parse(produto.prazoEntrega, 'dd/MM/yyyy', new Date('2020-01-01'));
				if (!isValid(prazoEntregaDate) || isAfter(prazoEntregaDate, new Date('2100-01-01'))) {
					errors.prazoEntrega =
						'O prazo de entrega do produto "' + produto.codigoProduto + '" não é uma data válida';
				} else if (isBefore(prazoEntregaDate, addDays(new Date(), -1))) {
					errors.prazoEntrega =
						'O prazo de entrega do produto "' +
						produto.codigoProduto +
						'" não pode ser menor que a data atual';
				}
			}
		}

		for (const parcela of diasParcelas) {
			if (!parcela) {
				errors.quantidadeParcelas = 'Existem uma ou mais parcelas com dias inválidos';
			}
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		cnpjTransportadora: Yup.string().nullable(true),
		observacao: Yup.string().nullable(true).max(2000, 'A observação pode conter até 2000 caracteres'),
	}),
	handleSubmit: () => {},
})(Cotacao);

export default CotacaoForm;
