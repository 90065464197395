import { parse } from "date-fns";

export function validarPrazoEntregaPadrao(prazoEntrega) {

    if (!prazoEntrega) {
        return true;
    }

    const dataAtual = new Date();
    dataAtual.setHours(0, 0, 0, 0);
    const prazoEntregaDate = parse(prazoEntrega, "dd/MM/yyyy", dataAtual);
    const dataMaxima = parse("01/01/2100", "dd/MM/yyyy", dataAtual);
    if (prazoEntrega.length === 10 && prazoEntregaDate >= dataAtual && prazoEntregaDate < dataMaxima) {
        return true;
    }

    return false;
}

export function buscarValueInicial(produtos) {
    let value = []
    produtos && produtos.forEach(produto => {
        value.push({ codigoProduto: produto.codigoProduto, identificador: produto.identificador })
    })
    return value
}