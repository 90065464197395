import { makeStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
    content: {
        paddingTop: 64,
        display: 'flex',
        minHeight: 'calc(100vh - 50px)',
        backgroundColor: theme.palette.background.white
    }
}));

export default styles;