import React from 'react';
import LottieAnimation from '../../../../components/LottieAnimation';
import Grid from '@material-ui/core/Grid';
import Error from '../../../../animations/error.json';
import PropTypes from 'prop-types';
import StatusCotacao from '../StatusCotacao';

function CotacaoExpirada({ dataExpiracao, hidden }) {
	if (hidden) {
		return null;
	}
	return (
		<Grid container justifyContent="center" alignItems="center" direction="column">
			<Grid item>
				<Grid container justifyContent="center" alignItems="center">
					<LottieAnimation height={75} autoplay animationData={Error} />
				</Grid>
				<StatusCotacao
					title={'Cotação expirada!'}
					text={` A cotação expirou no dia ${dataExpiracao} e não pode mais ser respondida`}
					backgroundColor={'#ffcdd2'}
					color={'#da1f1f'}
				/>
			</Grid>
		</Grid>
	);
}

CotacaoExpirada.propTypes = {
	dataExpiracao: PropTypes.string,
	hidden: PropTypes.bool,
};

export default CotacaoExpirada;
